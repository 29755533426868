import { request } from './api';

export const signIn = ({email, password}) => {
  console.log(email, password)
  return request(`/login_check`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  });
};

export const forgotPassword = (email) => request(`/auth/forgot-password?email=${email}`, {});

export const currentUserInfo = () => {
  return request(`/auth/info`, {});
};

export const changePassword = (password, token) => {
  return request(`/auth/change-password`, {
    method: 'POST',
    body: JSON.stringify({
      password,
      token
    })
  });
};

export const logout = () => {
  return request(`/auth/logout`, {
    method: 'POST',
  });
};

export const verify = (auth_code) => {
  return request(`/login_2fa_check`, {
    method: 'POST',
    body: JSON.stringify({
      auth_code
    })
  });
};
