import React, {useEffect, useState} from 'react';
import {getETHUSDTData} from "@api/bybit";
import { useNavigate } from 'react-router-dom';
import { Price } from '../Mercury/Price'; // Adjust the path as necessary
import LOGO from '../../assets/logo.png'; // Adjust the path as necessary

import styles from './styles.module.scss';

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <div className={styles.logoAndPrice}>
        <div className={styles.logo}><img src={LOGO} /></div>
        <div className={styles.ethPrice}>
          <span className={styles.ethLabel}>BTCUSDT</span>
          <span className={styles.ethValue}> <Price /></span>
        </div>
      </div>
     
      <button onClick={() => navigate('/new-account')} className={styles.createAccountBtn}>
        Створити акаунт
      </button>
    </div>
  )
}

export default Header;
