import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"

export const LoadingContext = React.createContext(false)

export const LoadingHOC = ({ children }) => {
  const [loadingContextData, setLoadingContextData] = useState(false)

  return (
    <LoadingContext.Provider
      value={{ loading: loadingContextData, setLoading: setLoadingContextData }}
    >
      {children}
      {loadingContextData && (
        <Grid container justifyContent={"center"} style={{position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'}}>
          <CircularProgress />
        </Grid>
      )}
    </LoadingContext.Provider>
  )
}
