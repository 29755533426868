import React from 'react';
import styles from './styles.module.scss';
import AttentionIcon from "../../../assets/icons/attention.svg";

const Modal = ({ title, value, onChange, onSave, onClose }) => (
  <div className={styles.modal}>
    <div className={styles.modalContent}>
      <h2 className={styles.modalHeader}>{title}</h2>
      <p className={styles.modalWarning}>
        <img src={AttentionIcon} alt="Attention" />
        Змінює значення в усіх акаунтах
      </p>
      <input type="text" value={value} onChange={onChange} className={styles.modalInput} />
      <button onClick={onSave} className={styles.modalSaveBtn}>
        Зберегти
      </button>
    </div>
  </div>
);

export default Modal;
