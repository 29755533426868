import React from "react"
import { withMercure } from '@cerati/react-mercure';

function Place({ mercureData }) {
  return (
    <React.Fragment>
        {mercureData.lastPrice}
    </React.Fragment>
  );
}

const config = {
  hub: 'https://api.obmenium.de/.well-known/mercure',
  topics: ['trade.lastPrice.tickers.BTCUSDT'],
  json: true,
};

export const Price = withMercure(config, {})(Place);