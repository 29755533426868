import React, { useEffect, useState } from "react";
import "./styles.css";

export const MessageContext = React.createContext("");

export const MessageNotificationHOC = ({ children }) => {
  const [MessageContextData, setMessageContextData] = useState("");
  const [successMessageContextData, setSuccessMessageContextData] = useState("");

  useEffect(() => {
    if (MessageContextData) {
      setTimeout(() => {
        setMessageContextData("");
      }, 15000);
    } else if (successMessageContextData) {
      setTimeout(() => {
        setSuccessMessageContextData("");
      }, 15000);
    } else return;
  }, [MessageContextData, successMessageContextData]);

  return (
    <MessageContext.Provider
      value={{
        errorMessage: MessageContextData,
        setErrorMessage: setMessageContextData,
        successMessage: successMessageContextData,
        setSuccessMessage: setSuccessMessageContextData,
      }}
    >
      {children}
      {MessageContextData && typeof MessageContextData === "string" && (
        <div className="alert error">
          {MessageContextData.toString()}
        </div>
      )}
      {MessageContextData && typeof MessageContextData === "object" && MessageContextData.errorDescription && (
        <div className="alert error">
          {MessageContextData?.errorDescription}
        </div>
      )}
      {successMessageContextData && typeof successMessageContextData === "string" && (
        <div className="alert success">
          {successMessageContextData.toString()}
        </div>
      )}
      {successMessageContextData && typeof successMessageContextData === "object" && (
        <div className="alert success">
          {successMessageContextData}
        </div>
      )}
    </MessageContext.Provider>
  );
};
