import React, {useContext, useState} from 'react';
import Button from '../Button';
import { MessageContext } from "@hoc/messageNotification";

const LoginForm = ({onLoginSuccess}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {setErrorMessage} = useContext(MessageContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email && password) {
      onLoginSuccess(email, password);
    } else {
      setErrorMessage('Email and password are required');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <label htmlFor="email">Логін</label>
      <input
        id="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <label htmlFor="password">Пароль</label>
      <input
        id="password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
      />
      <Button type="submit">Увійти</Button>
    </form>
  );
};

export default LoginForm;