import React, {useContext} from 'react';
import LoginForm from '../../LoginForm';
import { useNavigate } from 'react-router-dom';
import './style.css';
import {signIn} from "../../../api/auth";
import {MessageContext} from "../../../hoc/messageNotification";
import {LoadingContext} from "../../../hoc/loading";
import {addUser} from "../../../redux/actions/user";
import {useDispatch} from "react-redux";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setLoading} = useContext(LoadingContext);
  const {setErrorMessage} = useContext(MessageContext);

  const handleLoginSuccess = async (email, password, code) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const {login} = await signIn({email, password});

      if (login === 'success') {
        dispatch(addUser(email));
        navigate('/verify');
      }
    } catch (error) {
      setErrorMessage(error);
    };

    setLoading(false);
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Вхід до кабінету</h1>
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      </div>
    </div>
  );
};

export default LoginPage;