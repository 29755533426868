import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { create } from '../../api/account';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';
import './style.css';
import Header from "../Header";

const formFields = [
  { label: "Ім'я", type: "text", name: "name", placeholder: "Назва аккаунта"},
  { label: "Ціна Лонг", type: "number", name: "long_price", placeholder: "XXX" },
  { label: "Активний", type: "checkbox", name: "active" },
  { label: "Ціна Шорт", type: "number", name: "short_price", placeholder: "XXX" },
  { label: "Кількість", type: "number", name: "count", placeholder: "XXX" },
  { label: "О%", type: "number", name: "o_percent", placeholder: "XXX" },
  { label: "Тз сек.", type: "number", name: "tz_sec", placeholder: "XXX" },
  { label: "К%", type: "number", name: "k_percent", placeholder: "XXX" },
];

const radioOptions = [
  { label: "Hi", value: "Hi" },
  { label: "Лонг", value: "Лонг" },
  { label: "Шорт", value: "Шорт" },
];

const NewAccountPage = () => {
  const { loading, setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);
  const [account, setAccount] = useState({state: 'Hi',
    api_key: '',
    api_secret: ''});
  const navigate = useNavigate();

  const updateAccount = (name, value) => {
    setAccount((prevAccount) => ({
      ...prevAccount,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      await create(account);
      navigate('/table');
    } catch (error) {
      setErrorMessage(error.message || 'Failed to create account.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-account-page">
      <Header />
      <div className="account-form">
        <button
          onClick={() => navigate('/table')}
          style={{width:'40px', height: '40px', borderRadius: "1000px", display: 'flex', alignItems:'center', justifyContent:'center', backgroundColor:'#F6F6F6'}}
        >
          <ArrowBackIcon style={{color: '#1E1E1E'}}/>
        </button>
        <h3>Новий акаунт</h3>
        <div className="form-row" style={{display: 'flex', gap: '20px'}}>
          {formFields.map(({ label, type, name, placeholder }) => (
            <div key={name} className="form-group">
              <label>{label}</label>
              {type === 'checkbox' ? (
                <div className="toggle-switch">
                  <input
                    type={type}
                    id={name}
                    checked={account[name]}
                    onChange={(e) => updateAccount(name, e.target.checked)}
                  />
                  <label htmlFor={name} className="switch"></label>
                </div>
              ) : (
                <input
                  type='text'
                  name={name}
                  onChange={(e) => updateAccount(name, type === 'number' ? Number(e.target.value) : e.target.value)}
                  placeholder={placeholder}
                />
              )}
            </div>
          ))}
          <div className="form-group">
            <label>Статус</label>
            <div className="radio-group">
              {radioOptions.map(({ label, value }) => (
                <label key={value}>
                  <input
                    type="radio"
                    value={value}
                    checked={account.state === value}
                    onChange={(e) => updateAccount('state', e.target.value)}
                  />
                  {label}
                </label>
              ))}
            </div>
          </div>
        </div>
        <button disabled={loading} style={{width: '254px'}} onClick={handleSubmit}>Зберегти</button>
      </div>
    </div>
  );
};

export default NewAccountPage;
