import {clearToken, getToken} from "./token";
const {API_DOMAIN} = require(`../config/.env.${process.env.REACT_APP_ENV}.js`);

const headersDefault = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `${getToken()}`
    }
}

export const request = (url, options) => {
    return new Promise((res, rej) => {
      fetch(`${API_DOMAIN}${url}`, {headers: {...headersDefault(), ...options.headers}, credentials: 'include', mode: 'cors', ...options})
            .then(async (response) => {
              let data = null;

              switch(response.status) {
                    case 401:
                      clearToken();
                      const {errorDescription} = await response.json();
                      window.location.href = "/";
                      rej(errorDescription);
                      rej();
                      break;
                    case 400:
                        data = await response.json();
                        rej(data);
                    break;
                  case 304:
                  case 204:
                    res({});
                    break;
                  case 200:
                  case 201:
                      res(response.json());
                  break;
                  default:
                  data = await response.json();
                  rej(data);
                }
            })
    })
};

export const requestHeader = (url, options) => {
    return new Promise((res, rej) => {
        fetch(`${API_DOMAIN}${url}`, {headers: {...headersDefault(), ...options.headers}, ...options})
            .then(async (response) => { 
                let data = null;

                switch(response.status) {
                    case 406:
                        data = await response.json();
                        data.status = response.status;
                        rej(data);
                        break;
                    case 404:
                        data = await response.json();
                        data.status = response.status;
                        rej(data);
                        break;
                    case 401: 
                        // clearToken()
                        // window.location.replace('/signin')
                    break;
                    case 400:
                        data = await response.json();
                        rej(data);
                    break;
                    case 200: 
                        const headers = {}
                        for (var pair of response.headers.entries()) {
                        headers[pair[0]] = pair[1]
                        }
                        res(Promise.all([response.json(), Promise.resolve(headers)]));
                    break;
                    default:
                    data = await response.json();
                    rej(data.message);
                }
            })
    })
};

export const mltipartRequest = (url, options) => {
    return new Promise((res, rej) => {
        fetch(`${API_DOMAIN}${url}`, {headers: {'Authorization': `${getToken()}`, ...options.headers}, ...options})
            .then(async (response) => {
                if (response.status !== 200) {
                    const data = await response.json();
                    if (response.status === 400) {
                        return rej(data);
                    }
                    return rej(data.message);
                }

                res(response.json());
            })
    })
};
