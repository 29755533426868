import React from 'react';
import { Navigate } from "react-router-dom";
import { getToken } from "../../api/token";
import { Grid } from "@mui/material";
import routes from "@routes/authorize";
import './style.css';

const NonAuthorizeContainer = ({ component: Component, ...rest }) => {
  const token = getToken();
  const main = routes.find(({ main }) => main);

  return (
    <Grid container height="100vh" alignItems="center" justifyContent={"center"}>
      {!token ? <Component {...rest} /> : <Navigate to={main.path} />}
    </Grid>
  );
};

export default NonAuthorizeContainer;