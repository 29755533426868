import React, { useState } from "react"
import {getRole} from "../api/token";

export const RoleContext = React.createContext(false)

export const RoleHOC = ({ children }) => {
  const [role, setRole] = useState(getRole())

  return (
    <RoleContext.Provider
      value={{ role, setRole }}
    >
      {children}
    </RoleContext.Provider>
  )
}
