import {request} from "./api";
import {getToken} from "./token";

export const create = (data) => {
  return request(`/account`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const get = (page) => {
  return request(`/accounts?page=${page}`, {
    method: 'GET'
  });
};

export const getById = (id) => {
  return request(`/account/${id}`, {
    method: 'GET'
  });
};

export const edit = (data, id) => {
  return request(`/account/${id}`, {
    method: 'PATCH',
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/merge-patch+json',
      'Authorization': `${getToken()}`
    },
    body: JSON.stringify(data),
  });
};

export const editBatch = (data) => {
  return request(`/accounts`, {
    method: 'PATCH',
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/merge-patch+json',
      'Authorization': `${getToken()}`
    },
    body: JSON.stringify(data),
  });
};

export const deleteAccount = (id) => {
  return request(`/account/${id}`, {
    method: 'DELETE'
  });
};