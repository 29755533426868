import React from "react";
import "./style.css";
import { Navigate } from 'react-router-dom';
import { getToken } from "../../api/token";
import { Grid } from "@mui/material";

const AuthorizeContainer = ({ component: Component, props }) => {
  let token = getToken();

  return (
    <Grid item xs={12}>
      {token ? (
        <div className="main-container">
          <div className="main-sidebar">
            <Grid container spacing={0} className="main-sidebar-container">
              <Component {...props} />
            </Grid>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </Grid>
  );
};

export default AuthorizeContainer;
