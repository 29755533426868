import React from 'react';
import './style.css';

const Button = ({ type, children }) => {
  return (
    <button type={type} className="btn">
      {children}
    </button>
  );
};

export default Button;