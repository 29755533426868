const tokenName = 'token';
const BEARER = 'Bearer';

export const getRole = (token = getToken()) => {
    if(!token) return null

    // const replaceStr = `${BEARER} `
    // // const {role} = jwtDecode(token.replace(new RegExp(replaceStr, "g"), ''), { header: true });
    //
    // return 'ADMIN';
}

export const getToken = () => {
    let data = "";
    if (localStorage.getItem(tokenName)) {
        data = localStorage.getItem(tokenName);
    } else if (sessionStorage.getItem(tokenName)) {
        data = sessionStorage.getItem(tokenName);
    }

    if(!data) return ;

    const {token} = JSON.parse(data);

    return `${BEARER} ${token}`;
}

export const setToken = (token, remember) => {
    if (token && typeof token === 'object' && remember) {
        return localStorage.setItem(tokenName, JSON.stringify(token));
    } else if (token && typeof token === 'object' && !remember) {
        return sessionStorage.setItem(tokenName, JSON.stringify(token));
    }
};

export const clearToken = () => {
    if (sessionStorage.getItem(tokenName)) {
        sessionStorage.removeItem(tokenName);
    } else if (localStorage.getItem(tokenName)) {
        localStorage.removeItem(tokenName);
    }

    window.location.reload();

    return false;
};
