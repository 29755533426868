import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Autorize from './routes/authorize';
import NonAutorize from './routes/non-authorize';

import NonAuthorizeContainer from './containers/NonAuthorizeContainer';
import AuthorizeContainer from './containers/AuthorizeContainer';
import CssBaseline from "@mui/material/CssBaseline";
import {Hoc} from './hoc';

function App() {
  return (
    <Hoc>
      <CssBaseline/>
      <Router>
        <Routes>
          {Autorize.map((obj) => {
            return <Route path={obj.path}
                          element={
                            <AuthorizeContainer key={obj.path} {...obj}></AuthorizeContainer>
                          }
            />})}
          {NonAutorize.map((obj) => {
            return <Route path={obj.path}
                          {...obj}
                          element={
                            <NonAuthorizeContainer key={obj.path} {...obj}></NonAuthorizeContainer>
                          }
            />})}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </Hoc>
  );
};

export default App;
