import TablePage from "../components/TablePage";
import NewAccountPage from "../components/NewAccountPage";
import EditAccountPage from "../components/EditAccountPage";

const routes = [
  { path: '/table', component: TablePage, label: 'Table page', menu: true, role: 'ADMIN', main: true },
  { path: '/new-account', component: NewAccountPage, default: false, main: false },
  { path: '/edit-account/:id', component: EditAccountPage, default: false, main: false }
];

export default routes;
