import React, { useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import { get } from "../../../api/history";
import { LoadingContext } from "../../../hoc/loading";
import { MessageContext } from "../../../hoc/messageNotification";

const HistoryModal = ({ account, onClose }) => {
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  useEffect(() => {
    const fetchAccount = async () => {
      setLoading(true);
      setErrorMessage(null);
      try {
        await get(account.id); // Removed unused 'obj' variable
      } catch (error) {
        setErrorMessage(error.message || 'Failed to fetch accounts.');
      } finally {
        setLoading(false);
      }
    };

    fetchAccount(); // Call the fetch function inside useEffect
  }, [account.id, setLoading, setErrorMessage]); // Added necessary dependencies

  return (
    <div className={styles.historyModal}>
      <div className={styles.historyModalContent}>
        <div className={styles.historyHeader}>
          <h2>Історія</h2>
          <button onClick={onClose} className={styles.closeHistoryBtn}>
            &times;
          </button>
        </div>

        <div className={styles.historyEntry}>
          <div className={styles.historyRow}>
            <span>Відкриття</span>
            <span className={`${styles.status} ${styles.long}`}>Лонг</span>
          </div>
          <div className={styles.historyRow}>
            <span>Закриття</span>
            <span className={`${styles.status} ${styles.short}`}>Шорт</span>
          </div>
          <div className={styles.historyRow}>
            <span>Ціна</span>
            <span>20 USD</span>
          </div>
          <div className={styles.historyRow}>
            <span>Кількість</span>
            <span>457,54 USDT</span>
          </div>
          <div className={styles.historyTimestamp}>
            12.03.2024 12:33:23
          </div>
        </div>

        <button onClick={onClose} className={styles.clearHistoryBtn}>
          Стерти
        </button>
      </div>
    </div>
  );
}

export default HistoryModal;
