import React from "react"

import {LoadingHOC} from "./loading"
import {MessageNotificationHOC} from "./messageNotification"
import {RoleHOC} from "./role"
// import {MercuryHOC} from "./mercury"

export const Hoc = ({children}) => {
    return (
      <RoleHOC>
        <LoadingHOC>
          <MessageNotificationHOC>
              {children}
          </MessageNotificationHOC>
        </LoadingHOC>
      </RoleHOC>
    )
}
